<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Schedules" backTo="service">
      <router-link
          :to="{ name: 'schedule-create' }"
      >

      </router-link>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-0 mr-2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected"/>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <div class="flex items-center mr-4">
                <label class="mr-2">From Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="fromDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <div class="flex items-center ">
                <label class="mr-2">To Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="toDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>

              <StaffAutocomplete class="ml-6 w-full lg:w-1/5 xl:w-1/5 2xl:w-1/5" v-model="selectedEngineerInput"
                                 :forceSelection="true"/>

              <input
                  type="text"
                  class="ml-6 w-full lg:w-auto rounded-l bge-input bge-input-spacing"
                  placeholder="Search "
                  v-model="searchTextInput"
                  @keydown.enter="triggerSearch"
              />
              <button
                  @click="triggerSearch"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <Icon iconType="search"/>
              </button>
            </div>
            <!--            <div class="flex justify-end mt-5">-->
            <!--              <StaffAutocomplete class="mr-8 px-2 w-full lg:w-1/4 "/>-->
            <!--            </div>-->
          </div>
        </div>

        <!-- Tab Content -->

        <TabContent :currentTab="currentTab">
          <template v-if="currentTab === 'my_schedule'" v-slot:my_schedule>
            <DataTableComponent :columns="my_schedule_columns" @row-click="onRowClick" rowGroupMode="rowspan"
                                groupRowsBy="schedule_date" key="my_schedule"
                                :enableRowClick=false>

              <template v-slot:schedule-date-slot="{ rowData }">
                {{ rowData.schedule_date }}
              </template>

              <template v-slot:schedule-day-slot="{ rowData }">
                {{ rowData.schedule_day }}
              </template>

              <template v-slot:timestamp-slot="{ rowData }">
                {{ formatTimestamp(rowData.warranty_expiry) }}
              </template>

              <template v-slot:owner-postcode-slot="{ rowData }">
                <div v-if="rowData.customer.default_address">
                  {{ getDefaultAddressPostcode(rowData.customer.default_address) }}
                </div>
              </template>

              <template v-slot:owner-phone-slot="{ rowData }">
                <div v-if="rowData.customer.default_person">
                  {{ getDefaultPeoplePhone(rowData.customer.default_person) }}
                </div>
              </template>

              <template v-slot:engineer-name-slot="{ rowData }">
            <span v-if="rowData.engineer">
            {{ rowData.engineer.first_name }} {{ rowData.engineer.last_name }}
           </span>
              </template>

              <template v-slot:scheduled-slot="{ rowData }">
                <span v-if="rowData.active_schedule_id">{{ 'Yes' }}</span>
                <span v-else>{{ 'No' }}</span>
              </template>
              <template v-slot:job-id-slot="{ rowData }">
                <router-link
                    class="text-blue-600 hover:text-blue-800 underline cursor-pointer"
                    :to="{ name: 'engineer-schedule-view', params: { job_id: rowData.job_id }}"
                >
                  {{ rowData.job_id }}
                </router-link>
              </template>

            </DataTableComponent>
          </template>
        </TabContent>


      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/HR/Engineer/MySchedule/Components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";

const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Spinner,
    Panel,
    IsAuthorized,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
    StaffAutocomplete
  },
  data() {
    return {
      tabs: [
        {id: "my_schedule", label: "Schedules",},
      ],
      my_schedule_columns: [
        {
          field: "schedule_date",
          header: "Day",
          sortable: false,
          custom: true,
          slotName: "schedule-day-slot",
          width: '10%',
        },
        {
          field: "",
          header: "Date",
          custom: true,
          slotName: "schedule-date-slot",
          width: '8%',
        },
        {
          field: "schedule_time",
          header: "Time",
          sortable: false,
          custom: false,
          width: '8%',
        },
        {
          field: "customer.name",
          header: "Owner Name",
          sortable: false,
          custom: false,
          width: '15%',
        },
        {
          field: "customer.default_address.postcode",
          header: "Postcode",
          sortable: false,
          custom: true,
          slotName: "owner-postcode-slot",
          width: '8%',
        },
        {
          field: "owner_info.people[0].telephone",
          header: "Telephone",
          sortable: false,
          custom: true,
          slotName: "owner-phone-slot",
          width: '10%',
        },
        {
          field: "equipment.product_code",
          header: "Code",
          sortable: false,
          custom: false,
          width: '8%',
        },
        {
          field: "equipment.name",
          header: "Description",
          sortable: false,
          custom: false,
          width: '15%',
        },
        {
          field: "engineer",
          header: "Engineer",
          sortable: false,
          custom: true,
          slotName: "engineer-name-slot",
          width: '10%',
        },
        {
          field: "job_id",
          header: "Job ID",
          sortable: false,
          custom: true,
          slotName: "job-id-slot",
          width: '5%',
        },
      ],
    };
  },
  computed: {
    ...mapState("mySchedule", ["currentTab", "searchText", "selectedEngineer", "fromDate", "toDate"]),

    searchTextInput: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.$store.commit("mySchedule/UPDATE_SEARCH_TEXT", value);
      },
    },

    selectedEngineerInput: {
      get() {
        return this.selectedEngineer;
      },
      set(value) {
        this.$store.commit("mySchedule/UPDATE_SELECTED_ENGINEER", value);
      },
    },

    fromDateInput: {
      get() {

        const fromDate = this.fromDate;
        return fromDate ? new Date(fromDate) : null;
      },
      set(value) {

        this.$store.commit("mySchedule/UPDATE_FROM_DATE", value);
      }
    },

    toDateInput: {
      get() {
        const toDate = this.toDate;
        return toDate ? new Date(toDate) : null;
      },
      set(value) {
        this.$store.commit("mySchedule/UPDATE_TO_DATE", value);
      }
    },

  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("mySchedule", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
    ]),

    validateDates() {
      const hasFromDate = !!this.fromDateInput;
      const hasToDate = !!this.toDateInput;

      if (hasFromDate && hasToDate) {
        if (this.toDateInput < this.fromDateInput) {
          this.notifyWarning("To Date should not be earlier than From Date.");
          return false;
        }
        return true;
      }

      if (hasFromDate !== hasToDate) {
        this.notifyWarning("Please select both the From Date and To Date.");
        return false;
      }

      return true;
    },

    getDefaultAddressPostcode(defaultAddress) {
      return defaultAddress ? defaultAddress.postcode : 'N/A';
    },
    getDefaultPeoplePhone(defaultPerson) {
      return defaultPerson ? defaultPerson.mobile || defaultPerson.telephone : 'N/A';
    },
    onRowClick(event) {

      this.Edit(event.data);
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    onTabSelected(newTab) {
      this.setTab(newTab);
    },
    setTab(newTab) {
      this.$store.dispatch("mySchedule/updateTab", newTab);
    },
    triggerSearch() {
      if (this.validateDates()) {
        this.setTab("my_schedule");
      }
    },

    Edit(rowObj) {

      let routeName;
      let params = {};

      if (rowObj.active_schedule_id) {
        routeName = "schedule-edit";
        params.schedule_id = rowObj.active_schedule_id;
      } else {
        routeName = "schedule-create";
        if (rowObj.owner_info && rowObj.owner_info.contact_id) {

          this.$router.push({
            name: routeName,
            query: {customer_id: rowObj.owner_info.contact_id}
          });
          return;
        }
      }

      this.$router.push({
        name: routeName,
        params: params,
      });
    },


  },
};
</script>
